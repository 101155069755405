import { map } from 'lodash';
import * as Tone from 'tone';
import { Z_SYNC_FLUSH } from 'zlib';
import PlaybackSettings from '../components/PlaybackSettings';
import { getMetronomePlaybackPatterns, MetronomeSettings } from './metronome';
import { getTotalDuration, getPlaybackPatternsForNoteGroup } from './note';
import { PlaybackPattern, Pitch, Octave, PitchClass } from './note-definition';
import { TimeSignature, TimeSignatureComplexity } from './time-signature';
import { Measure } from './vex';

export enum PlaybackState {
  PLAYING,
  STOPPED,
}
export type NoteTriggerHandler = (index: number | null) => void;

const toneDurationFactorNoteSpacingMap: { [toneDuration: string]: number } = {
  '1': 0.9,
  '2': 0.875,
  '4': 0.75,
  '8': 0.67,
  '16': 0.5,
  '32': 0.1,
};

const NOTE_SPACING = 0.75; // %
const HEADING_TIME = 0.1; // seconds
const TRAILING_TIME = 1; // seconds
const masterEnvelope = new Tone.Envelope();

export const Transport = Tone.Transport;

const getPlaybackSynth = () => {
  masterEnvelope.sustain= 0.1;
  masterEnvelope.decay=20;

  const synth = new Tone.Synth({envelope: masterEnvelope}).toDestination();
  return synth;
};

const getMetronomeSynth = () => {
  const synth = new Tone.Synth().toDestination();

  synth.envelope.attack = 0.005;
  synth.envelope.decay = 0.05;
  synth.envelope.sustain = 0;
  synth.envelope.release = 0;

  return synth;
};

export const startPlayback = (
  measures: Measure[],
  pitch: Pitch,
  timeSignature: TimeSignature,
  metronomeSettings: MetronomeSettings,
  onNoteTrigger: NoteTriggerHandler,
  onMetronomeClickTrigger: NoteTriggerHandler
) => {
  Tone.start();
  Transport.cancel();

  Transport.timeSignature = timeSignature.beatsPerMeasure;

  let elapsedTime = HEADING_TIME;

  if (metronomeSettings.active) {
    scheduleMetronomeMeasures(
      measures.length + metronomeSettings.countOffMeasures,
      getMetronomeSynth(),
      elapsedTime,
      timeSignature,
      metronomeSettings,
      onMetronomeClickTrigger
    );

    if (metronomeSettings.countOffMeasures) {
      let countOffTime = Tone.Time(
        `${metronomeSettings.countOffMeasures}m`
      ).toSeconds();

      if (timeSignature.complexity === TimeSignatureComplexity.ALLA_BREVE) {
        countOffTime = countOffTime / 2;
      }

      elapsedTime += countOffTime;
    }
  }
  const synth=getPlaybackSynth();
  const synth2=getPlaybackSynth();
  scheduleMeasures(
    elapsedTime,
    synth,
    synth2,
    measures,
    pitch,
    timeSignature,
    onNoteTrigger
  );
  Transport.start();
};

export const stopPlayback = () => {
  Transport.stop();
};

export const updateTempo = (tempo: number) => {
  Transport.bpm.value = tempo;
};

const getPitchString = (pitch: Pitch) => {
  return `${pitch.pitchClass}${pitch.octave}`;
};

const triggerNote = (
  synth: Tone.Synth,
  playbackPattern: PlaybackPattern,
  index: number,
  pitch: Pitch,
  onNoteTrigger: NoteTriggerHandler
) => {
  return (time: number) => {
    onNoteTrigger(index);
    if (!playbackPattern.rest) {
      let timeToAdd = Tone.Time(playbackPattern.toneDuration).toSeconds();

      // Tone.js treats `1n` (the whole note) as a whole measure, regardless of time signature.
      // Convert it to be strictly 4 beats
      if (playbackPattern.toneDuration === '1n') {
        timeToAdd = Tone.Time('4n').toSeconds() * 4;
      }

      // Handle dotted whole notes similarly
      if (playbackPattern.toneDuration === '1n.') {
        timeToAdd = Tone.Time('4n').toSeconds() * 6;
      }

      const toneDurationFactor = playbackPattern.toneDuration.replace(
        /\D/g,
        ''
      );

      const noteSpacing = toneDurationFactorNoteSpacingMap[toneDurationFactor];

      synth.triggerAttackRelease(
        getPitchString(pitch),
        timeToAdd * noteSpacing,
        time
      );
    }
  };
};

const scheduleMeasures = (
  elapsedTime: number,
  synth: Tone.Synth,
  secondLineSynth: Tone.Synth,
  measures: Measure[],
  pitch: Pitch,
  timeSignature: TimeSignature,
  onNoteTrigger: NoteTriggerHandler
) => {
  if (measures.length === 0) {
    return;
  }

  let playbackPatternIndex = 0;
  const callbacksToSchedule: Map<number,Set<(time: number) => void>> = new Map();
  measures.forEach((measure) => {
    const measureIndex=playbackPatternIndex;
    const measureElapsedTime=elapsedTime;
    if (getTotalDuration(measure.noteGroups) === 0) {
      return;
    }

    measure.noteGroups.forEach((noteGroup) => {
      const playbackPatterns = getPlaybackPatternsForNoteGroup(
        noteGroup,
        timeSignature
      );

      if (playbackPatterns.length === 0) {
        return;
      }

      playbackPatterns.forEach((playbackPattern) => {
        /*Transport.schedule(
          triggerNote(
            synth,
            playbackPattern,
            playbackPatternIndex,
            pitch,
            onNoteTrigger
          ),
          elapsedTime
        );*/
        var callback=triggerNote(
          synth,
          playbackPattern,
          playbackPatternIndex,
          pitch,
          onNoteTrigger
        );
        if(!callbacksToSchedule.has(elapsedTime)){
          callbacksToSchedule.set(elapsedTime,new Set<(time: number)=>void>().add(callback));
        }else if(callbacksToSchedule.get(elapsedTime) != undefined){
          callbacksToSchedule.get(elapsedTime)?.add(callback);
        }

        let timeToAdd = Tone.Time(playbackPattern.toneDuration).toSeconds();

        // Tone.js treats `1n` (the whole note) as a whole measure, regardless of time signature.
        // Convert it add strictly 4 beats of time. However, whole rests should take up the whole
        // measure, so no need to apply this behavior if the note is a rest
        if (playbackPattern.toneDuration === '1n' && !playbackPattern.rest) {
          timeToAdd = Tone.Time('4n').toSeconds() * 4;
        }

        // Handle dotted whole notes similarly
        if (playbackPattern.toneDuration === '1n.') {
          timeToAdd = Tone.Time('4n').toSeconds() * 6;
        }

        elapsedTime += timeToAdd;
        playbackPatternIndex += 1;
      });
    });
    if(measure.secondLineNoteGroups!=undefined){
      elapsedTime=measureElapsedTime;
      const pitch2: Pitch= {
        octave: Octave._4,
        pitchClass: PitchClass.C
      
      };
      measure.secondLineNoteGroups.forEach((noteGroup) => {
        const playbackPatterns = getPlaybackPatternsForNoteGroup(
          noteGroup,
          timeSignature
        );

        if (playbackPatterns.length === 0) {
          return;
        }

        playbackPatterns.forEach((playbackPattern) => {
          /*Transport.schedule(
            triggerNote(
              secondLineSynth,
              playbackPattern,
              playbackPatternIndex,
              pitch2,
              onNoteTrigger
            ),
            elapsedTime
          );*/
          var callback=triggerNote(
            secondLineSynth,
            playbackPattern,
            playbackPatternIndex,
            pitch2,
            onNoteTrigger
          );
          if(!callbacksToSchedule.has(elapsedTime)){
            callbacksToSchedule.set(elapsedTime,new Set<(time: number)=>void>().add(callback));
          }else{
            callbacksToSchedule.get(elapsedTime)?.add(callback);
          }

          let timeToAdd = Tone.Time(playbackPattern.toneDuration).toSeconds();

          // Tone.js treats `1n` (the whole note) as a whole measure, regardless of time signature.
          // Convert it add strictly 4 beats of time. However, whole rests should take up the whole
          // measure, so no need to apply this behavior if the note is a rest
          if (playbackPattern.toneDuration === '1n' && !playbackPattern.rest) {
            timeToAdd = Tone.Time('4n').toSeconds() * 4;
          }

          // Handle dotted whole notes similarly
          if (playbackPattern.toneDuration === '1n.') {
            timeToAdd = Tone.Time('4n').toSeconds() * 6;
          }

          elapsedTime += timeToAdd;
          playbackPatternIndex += 1;
        });
      });
    }
    
  });
  
  callbacksToSchedule.forEach((callbacks,time,map) => {
    var t2: number =time;
    Transport.schedule((t: number) => {
      callbacks.forEach(call=>{
        call(t);
      });
    },t2);
  });
  Transport.schedule(() => onNoteTrigger(null), elapsedTime);
  elapsedTime += TRAILING_TIME;
  Transport.schedule(stopPlayback, elapsedTime);
};

const scheduleMetronome = (
  synth: Tone.Synth,
  elapsedTime: number,
  timeSignature: TimeSignature,
  metronomeSettings: MetronomeSettings,
  scheduleNext: boolean,
  onMetronomeClickTrigger: NoteTriggerHandler
) => {
  const playbackPatterns = getMetronomePlaybackPatterns(
    timeSignature,
    metronomeSettings
  );

  playbackPatterns.forEach((playbackPattern, index) => {
    if (playbackPattern.pitch) {
      Transport.schedule(
        triggerNote(
          synth,
          playbackPattern,
          index,
          playbackPattern.pitch,
          (i) => {
            // Schedule next measure of clicks if scheduling last click
            if (scheduleNext && i === playbackPatterns.length - 1) {
              scheduleMetronome(
                synth,
                elapsedTime,
                timeSignature,
                metronomeSettings,
                true,
                onMetronomeClickTrigger
              );
            }

            onMetronomeClickTrigger(i);
          }
        ),
        elapsedTime
      );
    }

    elapsedTime += Tone.Time(playbackPattern.toneDuration).toSeconds();
  });

  return elapsedTime;
};

const scheduleMetronomeMeasures = (
  measureCount: number,
  synth: Tone.Synth,
  elapsedTime: number,
  timeSignature: TimeSignature,
  metronomeSettings: MetronomeSettings,
  onMetronomeClickTrigger: NoteTriggerHandler
) => {
  for (let count = 0; count < measureCount; count++) {
    elapsedTime = scheduleMetronome(
      synth,
      elapsedTime,
      timeSignature,
      metronomeSettings,
      false,
      onMetronomeClickTrigger
    );
  }
};

export const startMetronome = (
  timeSignature: TimeSignature,
  metronomeSettings: MetronomeSettings,
  onMetronomeClickTrigger: NoteTriggerHandler
) => {
  Tone.start();
  Transport.cancel();

  scheduleMetronome(
    getMetronomeSynth(),
    HEADING_TIME,
    timeSignature,
    metronomeSettings,
    true,
    onMetronomeClickTrigger
  );

  Transport.start();
};

export const stopMetronome = () => {
  Transport.stop();
  Transport.cancel();
};
